import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PortfolioItem from '../components/PortfolioItem';
import HeroSection from '../components/HeroSection';
import Services from '../components/Services';
import Team from '../components/Team';
import Technologies from '../components/Technologies';
import FAQ from '../components/FAQ';
import ScrollToTop from '../components/ScrollToTop';

import senderoarte from '../assets/senderoarte.png';
import mypymecontrol from '../assets/mypymecontrol.png';
import habanaflor from '../assets/habanaflor.jpg';
import logo from '../assets/logo.png'; 

import cubanbiodiversity from '../assets/cubanbiodiversity.png';
import oshedisrl from '../assets/oshedi.jpg'; 

const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Enviando...');
    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setStatus('Mensaje enviado correctamente');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatus('Error al enviar el mensaje');
      }
    } catch (error) {
      setStatus('Error al enviar el mensaje');
    }
  };
  return (
    <div className="bg-gray-900 dark:bg-gray-900 text-white">
      <Helmet>
        <title>AEWhite Devs</title>
        <link rel="icon" href={logo} />
      </Helmet>
      <Navbar />
      <HeroSection />


      <main className="flex-grow mx-auto px-4 py-8 fade-in w-full">
        <section id="about" className="py-16 bg-gray-900 text-white dark:bg-gray-800">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-12 slide-up">Sobre Nosotros</h2>
            <div className="bg-secondary p-8 rounded-lg text-center dark:bg-gray-700">
              <p className="text-gray-300">AEWhite Devs es un grupo de desarrolladores apasionados por crear soluciones tecnológicas innovadoras. Nuestro equipo se especializa en una variedad de tecnologías para ofrecer productos de alta calidad a nuestros clientes.</p>
            </div>
          </div>
        </section>
        <Services />
        <Team />
        <Technologies />
        <section id="portfolio" className="py-16 bg-gray-800 text-white dark:bg-gray-700">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-12 slide-up">Portafolio</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
              <PortfolioItem 
                title="MyPyme Control" 
                description="Un sistema completo de gestión empresarial para pequeñas y medianas empresas." 
                imageUrl={mypymecontrol} 
                link="https://mypymecontrol.com"
              />
              <PortfolioItem 
                title="HabanaFlor" 
                description="Una tienda online de flores y regalos con entrega a domicilio en La Habana." 
                imageUrl={habanaflor} 
                link="https://habanaflor.com"
              />
              <PortfolioItem 
                title="SenderoArte" 
                description="Un blog sobre el arte y la ciencia, explorando diversas disciplinas y sus intersecciones." 
                imageUrl={senderoarte}
                link="https://senderoarte.com"
              />
              <PortfolioItem 
                title="Cuban Biodiversity" 
                description="Una Base de datos sobre la biodiversidad de cuba." 
                imageUrl={cubanbiodiversity}
                link="https://cubanbiodiversity.com"
              />
              <PortfolioItem 
                title="Oshedi SRL" 
                description="Una Tienda Online de productos varios." 
                imageUrl={oshedisrl}
                link="https://oshedisrl.com"
              />
              <PortfolioItem 
                title="Herramientas" 
                description="Un Paquete de Herramientas Sencillas creadas por AEWhite Devs." 
                imageUrl={mypymecontrol} 
                link="https://herramientas.aewhitedevs.com"
              />
            </div>
          </div>
        </section>

        <section id="contact" className="py-20 bg-gray-900 text-white dark:bg-gray-800">
          <div className="container mx-auto">
            <h2 className="text-5xl font-bold text-center mb-16 slide-up">Contacto</h2>
            <form className="bg-white p-10 rounded-lg max-w-2xl mx-auto dark:bg-gray-700 shadow-lg" onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-gray-700 dark:text-gray-300 font-semibold mb-2" htmlFor="name">Nombre</label>
                <div className="flex items-center bg-gray-700 rounded dark:bg-gray-600">
                  <span className="p-2">
                    <i className="fa fa-user text-white"></i>
                  </span>
                  <input 
                    type="text" 
                    id="name" 
                    className="w-full p-2 rounded-r bg-gray-700 text-white dark:bg-gray-600" 
                    value={formData.name}
                    onChange={handleChange}
                    required 
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 dark:text-gray-300 font-semibold mb-2" htmlFor="email">Email</label>
                <div className="flex items-center bg-gray-700 rounded dark:bg-gray-600">
                  <span className="p-2">
                    <i className="fa fa-envelope text-white"></i>
                  </span>
                  <input 
                    type="email" 
                    id="email" 
                    className="w-full p-2 rounded-r bg-gray-700 text-white dark:bg-gray-600" 
                    value={formData.email}
                    onChange={handleChange}
                    required 
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 dark:text-gray-300 font-semibold mb-2" htmlFor="message">Mensaje</label>
                <div className="flex items-center bg-gray-700 rounded dark:bg-gray-600">
                  <span className="p-2">
                    <i className="fa fa-comment text-white"></i>
                  </span>
                  <textarea 
                    id="message" 
                    className="w-full p-2 rounded-r bg-gray-700 text-white dark:bg-gray-600" 
                    value={formData.message}
                    onChange={handleChange}
                    required 
                  />
                </div>
              </div>
              <button type="submit" className="bg-accent hover:bg-primary text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out w-full">Enviar</button>
              {status && <p className="text-center mt-4">{status}</p>}
            </form>
          </div>
        </section>
      </main>
      <FAQ />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default Home;
